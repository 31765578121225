<template>
  <div class="signUp">
    <div class="signUpPage clearfix">
      <div class="loginTitle tc">Sign Up</div>
      <div v-if="!isSignUp">
        <div>
          <signUp
            :signType="'email'"
            :agreeChecked="checked"
            @successChange="successChange"
            @getLoading="btnLoadingChange"
          >
            <div slot="agreement">
              <p>
                <el-checkbox
                  style="margin-right: 10px"
                  v-model="checked"
                ></el-checkbox
                >I have read and agree
              </p>
              <p class="blank">
                <router-link target="_blank" to="/agreement" class="mainColor"
                  >《AiLa user agreement and privacy policy》</router-link
                >
              </p>
            </div>
            <el-button
              slot="footerBtn"
              type="primary"
              class="loginBut"
              :disabled="!checked"
              size="small"
              :loading="emailLoading"
            >
              {{ $t("immedRegister") }}</el-button
            >
          </signUp>
        </div>
        <div class="fr">
          {{ $t("hasAccount") }}，<span
            class="mainColor cursor"
            @click="toSign"
          >
            {{ $t("emailAladyTip2") }}></span
          >
        </div>
      </div>
      <div v-else class="SignUpBox">
        <div class="SignUpBoxContent">
          <i class="el-icon-success SignUpIcon mainColor"></i>
          <div style="margin-left: 10px; padding-top: 8px">
            <div class="tip_header">{{ $t("registerSuccess") }}</div>
            <div class="goLogin mainColor" @click="toSign">
              {{ $t("emailAladyTip2") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import signUp from "~/baseComponents/baseSign/signUp";
export default {
  components: {
    signUp,
  },
  data() {
    return {
      checked: true,
      emailLoading: false,
      isSignUp: false,
    };
  },
  methods: {
    successChange() {
      this.isSignUp = true;
    },
    btnLoadingChange(val) {
      this.emailLoading = val;
    },
    toSign() {
      this.$router.push("/sign");
    },
  },
};
</script>
<style lang="less" scoped>
.signUp {
  width: 100%;
  height: calc(100vh - 62px);
  background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/overseasMeeting/signBack.png")
    100% no-repeat;
  position: relative;
  .signUpPage {
    width: 465px;
    height: 440px;
    border-radius: 10px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 32px 30px;
    .loginTitle {
      font-size: 32px;
      color: #363d3b;
      font-weight: bold;
      margin-bottom: 28px;
    }
  }
  .loginBut {
    width: 390px;
    display: block;
    height: 40px;
    margin: 12px auto;
    border: 0 none;
    font-size: 16px;
  }
}
.SignUpBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  .SignUpBoxContent {
    display: flex;
    .SignUpIcon {
      font-size: 60px;
    }
  }
  .tip_header {
    font-weight: 600;
    font-size: 14px;
  }
  .goLogin {
    margin-top: 8px;
    cursor: pointer;
    font-weight: 600;
  }
  .small {
    font-size: 14px;
  }
}
.blank{
  font-size: 12px;
  margin-left: 20px;
}
</style>

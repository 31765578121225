<template>
  <div class="signup">
    <div>
      <!-- 邮箱 -->
      <el-form
        v-if="signType == 'email'"
        :model="emailForm"
        :rules="emailRules"
        ref="emailForm"
        :label-position="'top'"
        label-width="80px"
      >
        <el-form-item label prop="email">
          <el-input
            :placeholder="$t('enterEmail')"
            v-model="emailForm.email"
            nowritten
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            :placeholder="$t('wrongPasswordFormat')"
            type="password"
            v-model="emailForm.password"
            nowritten
            :show-password="true"
            autocomplete="off"
            @keyup.enter.native="sign"
          ></el-input>
        </el-form-item>
        <el-form-item prop="passwordAgain">
          <el-input
            :placeholder="$t('passwordAgain')"
            type="password"
            v-model="emailForm.passwordAgain"
            nowritten
            :show-password="true"
            autocomplete="off"
            @keyup.enter.native="sign"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 电话 -->
      <el-form
        v-if="signType == 'phone' && isSignUp === 1"
        :label-position="'top'"
        :model="phoneForm"
        ref="phoneForm"
        :rules="phoneRules"
      >
        <el-form-item label prop="phone">
          <el-input
            :placeholder="$t('enterTelephone')"
            v-model="phoneForm.phone"
            class="input-with-select"
            type="number"
          >
            <el-select
              v-model="phoneForm.areaCode"
              slot="prepend"
              popper-class="registerAreaCodeList"
              filterable
              :placeholder="$t('pleaseSelect')"
              :filter-method="areaCodeFilter"
            >
              <el-option
                v-for="(item, index) in areaCodeList"
                :key="item.area_code + index + ''"
                :value="item.area_code"
                :title="item.country | areacodeTitleFormat(LOCALE)"
              >
                <p class="area_code">{{ item.area_code }}</p>
                <p class="country textOverflow">
                  {{ item.country | areacodeAddressFormat(LOCALE) }}
                </p>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            style="width: 60%"
            :placeholder="$t('enterVCode')"
            type="text"
            v-model="phoneForm.code"
            @keyup.enter.native="sign"
            autocomplete="off"
            nowritten
          ></el-input>
          <el-button
            :disabled="codeBtnDisabled"
            class="fr getCodeBtn"
            @click="getCode"
          >
            {{ codeText }}
            <span v-if="parseInt(codeText) == codeText">&nbsp;s</span>
          </el-button>
          <div class="custom_error_tip" v-show="phoneErrorShow">
            {{ $t("invalidphoneAccount") }}
          </div>
          <div class="custom_error_tip" v-show="phoneDisabledShow">
            {{ $t("accountDisabledTip") }}
          </div>
        </el-form-item>
      </el-form>
      <slot name="agreement"></slot>
    </div>
    <div v-show="isSignUp === 2 && signType == 'phone'">
      <el-form
        :model="passWordForm"
        :rules="passRules"
        ref="passWordForm"
        class="register-ruleForm"
      >
        <div class="account">
          <el-form-item prop="pass">
            <el-input
              :placeholder="$t('enterPassword')"
              type="password"
              v-model="passWordForm.pass"
              class="input-with-select"
            ></el-input>
          </el-form-item>
        </div>
        <div class="cCode">
          <el-form-item prop="passAgain">
            <el-input
              :placeholder="$t('passwordAgain')"
              type="password"
              v-model="passWordForm.passAgain"
              class="input-with-select"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div @click="signUp" class="signBtn" v-if="signType == 'email'">
      <slot name="footerBtn"></slot>
    </div>
    <div @click="signUp" class="signBtn" v-else-if="isSignUp == 2">
      <slot name="footerBtn"></slot>
    </div>
    <div @click="passEdit" class="signBtn" v-else>
      <slot name="footerBtn1"></slot>
    </div>
  </div>
</template>
<script>
/** 事件:
 *1、successChange:登录成功反射的方法；
 *2、getLoading:点击登录在请求接口之前返回的方法（一般用来开启插槽按钮的loading）
*  插槽:
 *   footerBtn 登录按钮插槽（在父组件不用再写登录接口，只需要写样式文字和loading）
 
 *  注：邮件注册成功页面需要在父组件写
 */
import { areaCodeList } from "~/baseUtils/AddressConfig";
import {
  validateTelephone,
  validateEmailOrTelephone,
  validateEmail,
  validatePassWord,
} from "~/baseUtils/validate";
export default {
  props: {
    signType: {
      type: String,
      default: "phone",
    },
    agreeChecked: {
      type: Boolean,
      default: true,
    },
    extend: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isSignUp: 1,
      codeText: this.$t("getvCode"),
      emailForm: {
        email: "",
        password: "",
        passwordAgain: "",
      },
      phoneForm: {
        phone: "",
        code: "",
        areaCode: "+86",
      },
      passWordForm: {
        pass: "",
        passAgain: "",
      },
      codeBtnDisabled: false,
      signLoading: false,
      emailErrorShow: false,
      phoneErrorShow: false,
      emailDisabledShow: false,
      phoneDisabledShow: false,
      areaCodeList: areaCodeList,
      checkCode: false,
      areaCodeListCopy: [].concat(areaCodeList),
      emailRules: {
        email: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateEmail(value)) {
              callback(new Error(this.$t("wrongEmailFormat")));
            } else {
              try {
                let result = await this.$store.dispatch(
                  "baseStore/baseSign_checkIdentifier",
                  { identifier: value, type: 1 }
                );
                if (result.success) {
                  callback();
                } else {
                  if (result.errorcode == 1000) {
                    callback(new Error(this.$t("emailAladyTip")));
                  }
                  callback(new Error(" "));
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
              }
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validatePassWord(value)) {
              callback(new Error(this.$t("wrongPasswordFormat")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        passwordAgain: {
          validator: (rule, value, callback) => {
            if (this.emailForm.password == "") {
              callback();
            } else if (this.emailForm.password != "" && value == "") {
              callback(new Error(" "));
            } else if (
              this.emailForm.password != "" &&
              value !== this.emailForm.password
            ) {
              callback(new Error(this.$t("passwordMatch")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      phoneRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateTelephone(value)) {
              callback(new Error(this.$t("wrongTelephoneFormat")));
            } else {
              try {
                let result = await this.$store.dispatch(
                  "baseStore/baseSign_checkIdentifier",
                  { identifier: `${this.phoneForm.areaCode}${value}`, type: 2 }
                );
                if (result.success) {
                  callback();
                } else {
                  if (result.errorcode == 1000) {
                    callback(new Error(this.$t("phoneAladyTip")));
                  }
                  callback(new Error(" "));
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
              }
            }
          },
          trigger: "blur",
        },
        code: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              if (this.checkCode) {
                let result = await this.$store.dispatch(
                  "baseStore/checkVerifyCode",
                  {
                    area_code: this.phoneForm.areaCode.replace("+", ""),
                    mobile: `${this.phoneForm.phone}`,
                    ctype: 1,
                    code: this.phoneForm.code,
                  }
                );
                if (result.success) {
                  if (result.data) {
                    callback();
                  } else {
                    this.checkCode = false;
                    callback(new Error(this.$t("errorVerifyCode")));
                  }
                } else {
                  this.checkCode = false;
                  callback(new Error(" "));
                  this.$seTip();
                }
              }
            }
          },
          trigger: "blur",
        },
      },
      passRules: {
        pass: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (validatePassWord(value)) {
                  callback();
                } else {
                  return callback(new Error(this.$t("wrongPasswordFormat")));
                }
              } else {
                return callback(new Error(" "));
              }
            },
            trigger: "blur",
          },
        ],
        passAgain: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(" "));
              } else {
                if (value == this.passWordForm.pass) {
                  callback();
                } else {
                  return callback(new Error(this.$t("passwordMatch")));
                }
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    async getCode() {
      if (this.phoneForm.phone == "") {
        this.$message.warning(this.$t("enterTelephone"));
        return;
      }
      if (parseInt(this.codeText) == this.codeText) {
        return;
      }
      try {
        let result = { success: true };
        await this.$store.dispatch("baseStore/getVerifyCode", {
          area_code: this.phoneForm.areaCode.replace("+", ""),
          mobile: `${this.phoneForm.phone}`,
          ctype: 1,
        });
        if (result.success) {
          this.$message.success(this.$t("vcodeSendSuccess"));
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.codeText = TIME_COUNT;
            this.codeBtnDisabled = true;
            this.timer = setInterval(() => {
              if (this.codeText > 0 && this.codeText <= TIME_COUNT) {
                this.codeText--;
              } else {
                this.codeText = this.$t("getvCode");
                clearInterval(this.timer);
                this.timer = null;
                this.codeBtnDisabled = false;
              }
            }, 1000);
          }
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
    passEdit() {
      this.checkCode = true;
      this.$refs.phoneForm.validate(async (valid) => {
        if (valid) {
          this.isSignUp = 2;
        }
      });
    },
    signUp() {
      if (!this.agreeChecked) return;
      // this.$emit("getLoading", this.signType);
      let params = {};
      if (this.signType == "phone") {
        params.mobile = this.phoneForm.phone;
        params.password = this.passWordForm.pass;
        params.area_code = this.phoneForm.areaCode;
      } else {
        params = Object.assign({}, this.emailForm);
        delete params.passwordAgain;
      }
      let signType = this.signType;
      this[`${signType}ErrorShow`] = false;
      this[`${signType}DisabledShow`] = false;
      if (this.signType == "phone" && this.isSignUp == 2) {
        signType = "passWord";
      } else {
        signType = this.signType;
      }
      this.$refs[`${signType}Form`].validate(async (valid) => {
        if (valid) {
          this.$emit("getLoading", true);
          try {
            let result = await this.$store.dispatch(
              "baseStore/baseSign_Register",
              params
            );
            if (result.success) {
              this.$emit("successChange");
            } else {
            }
            this.$emit("getLoading", false);
          } catch (e) {
            console.log(e);
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.getCodeBtn {
  width: 38%;
}
</style>